import { createPluginFactory } from "@udecode/plate-common";

export const MARK_COLOR = "color";

export const createFontColorPlugin = createPluginFactory({
  isLeaf: true,
  key: MARK_COLOR,
  inject: {
    props: {
      nodeKey: MARK_COLOR,
    },
  },
  then: (editor, { type }) => ({
    deserializeHtml: {
      isLeaf: true,
      getNode: (element) => ({ [type]: element.style.color }),
      rules: [
        {
          validStyle: {
            color: "*",
          },
        },
      ],
    },
  }),
});
