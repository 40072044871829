import { useEffect, useState } from "react";
import { useFocused, useSelected } from "slate-react";

import { generateSrcAndSrcSet, TCloudImageElement, useUpload } from "..";

export const useCloudImageElementState = ({
  element,
}: {
  element: TCloudImageElement;
}) => {
  const upload = useUpload(element.id, element.url);

  const url =
    element.status === "uploaded"
      ? element.url
      : upload.status === "not-found"
        ? undefined
        : upload.url;

  const [size, setSize] = useState<{ width: number; height: number }>({
    width: element.width,
    height: element.height,
  });

  useEffect(() => {
    setSize({ width: element.width, height: element.height });
  }, [element.width, element.height]);

  const selected = useSelected();
  const focused = useFocused();

  const { src, srcSet } = generateSrcAndSrcSet({
    url: url,
    size: [element.width, element.height],
    maxSize: [element.maxWidth, element.maxHeight],
  });

  return {
    focused,
    selected,
    src,
    srcSet,
    size,
    upload,
    setSize,
  };
};
