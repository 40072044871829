"use client";

import { Button } from "@/components/ui/button";
import React, { useRef } from "react";
import { PlateElement, PlateElementProps, Value } from "@udecode/plate-common";
import { cn } from "@/lib/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
} from "@/components/ui/dialog";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ResizeControls } from "./cloud-resize-controls";
import { StatusBar } from "./cloud-status-bar";
import {
  PlateCloudEditor,
  TCloudImageElement,
  useCloudImageElementState,
} from "@/components/plate-ui/pi-cloud";
import { uploadFile as uploadFileToPiAws } from "@/components/plate-ui/pi-cloud/pi-aws-client";
import { ok } from "assert";

export interface CloudImageElementProps
  extends PlateElementProps<Value, TCloudImageElement> {
  editor: PlateCloudEditor;
}

export function CloudImageElement({
  className,
  children,
  element,
  editor,
  ...props
}: CloudImageElementProps) {
  const cropperRef = useRef<ReactCropperElement>(null);

  const onImageLoaded = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.reset();
    }
  };

  const approveImageUpload = (
    imageNode: TCloudImageElement,
    file: File,
    width: number,
    height: number,
  ) => {
    const url = URL.createObjectURL(file);

    editor.cloud.imageFileHandlers?.onProgress?.({
      id: imageNode.id ?? imageNode.url,
      type: "image",
      file,
      width,
      height,
      url,
      sentBytes: 0,
      totalBytes: file.size,
    });

    uploadFileToPiAws({
      client: editor.cloud.client,
      file: file,
      onProgress(e) {
        ok(e.clientFile.type === "image");

        editor.cloud.imageFileHandlers?.onProgress?.({
          id: imageNode.id ?? imageNode.url,
          type: "image",
          file: file,
          url: url,
          width,
          height,
          sentBytes: e.sentBytes,
          totalBytes: e.totalBytes,
        });
      },
      onError(e) {
        ok(e.clientFile.type === "image");

        editor.cloud.imageFileHandlers?.onError?.({
          id: imageNode.id ?? imageNode.url,
          type: "image",
          file: file,
          url: url,
          width,
          height,
          message: e.message,
        });
      },
      onSuccess(e) {
        ok(e.clientFile.type === "image");
        const hostedFile = e.hostedFile;
        ok(hostedFile.type === "image");

        const { url } = e.hostedFile;

        editor.cloud.imageFileHandlers?.onSuccess?.({
          id: imageNode.id ?? imageNode.url,
          type: "image",
          file: e.clientFile.file,
          width,
          height,
          url,
        });
      },
    });
  };

  const handleUpload = (forceOriginal: boolean = false) => {
    const cropper = cropperRef.current?.cropper;
    if (!cropper) return;

    const croppedDataInfo = cropper.getData(true);
    const { naturalWidth, naturalHeight } = cropper.getImageData();

    const isImageUnchanged =
      croppedDataInfo.x === 0 &&
      croppedDataInfo.y === 0 &&
      croppedDataInfo.rotate === 0 &&
      croppedDataInfo.scaleX === 1 &&
      croppedDataInfo.scaleY === 1 &&
      croppedDataInfo.width === naturalWidth &&
      croppedDataInfo.height === naturalHeight;

    if (forceOriginal || isImageUnchanged) {
      fetch(src)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], element.filename || "image.png", {
            type: blob.type,
          });
          approveImageUpload(
            element,
            file,
            croppedDataInfo.width,
            croppedDataInfo.height,
          );
        });
    } else {
      cropper
        .getCroppedCanvas({
          width: croppedDataInfo.width,
          height: croppedDataInfo.height,
          maxHeight: 1920,
          maxWidth: 1920,
        })
        .toBlob((blob: Blob | null) => {
          if (blob) {
            const file = new File([blob], "cropped.png", { type: "image/png" });
            approveImageUpload(
              element,
              file,
              croppedDataInfo.width,
              croppedDataInfo.height,
            );
          }
        });
    }
  };

  const { focused, selected, src, srcSet, size, upload, setSize } =
    useCloudImageElementState({ element });

  return (
    <PlateElement
      className={cn("relative my-4", className)}
      draggable
      editor={editor}
      element={element}
      {...props}
    >
      <span
        contentEditable={false}
        style={{
          width: "100%",
          position: "relative",
          display: "inline-flex",
          justifyContent: "center",
          verticalAlign: "top",
          userSelect: "none",
        }}
      >
        {src === "" ? (
          <div
            className={cn(
              "block rounded-lg",
              focused && selected && "shadow-[0_0_1px_3px_#60a5fa]",
            )}
            style={{
              width: size.width,
              height: size.height,
              background: "#e0e0e0",
            }}
          />
        ) : (
          <img
            className={cn(
              "block rounded-lg",
              focused && selected && "shadow-[0_0_1px_3px_#60a5fa]",
            )}
            src={src}
            srcSet={srcSet}
            width={size.width}
            height={size.height}
            alt=""
          />
        )}
        {element.status !== "uploaded" && (
          <div className="absolute inset-x-2 top-[50%] -mt-2">
            <StatusBar upload={upload} />
          </div>
        )}
        {selected && focused && (
          <ResizeControls element={element} size={size} setSize={setSize} />
        )}
      </span>
      {children}
      <Dialog
        open={element.status === "editing"}
        onOpenChange={() => handleUpload(true)}
      >
        <DialogContent aria-description="Yükleyeceğiniz fotoğrafı yüklemeden önce kırpabilirsiniz.">
          <DialogTitle>Fotoğraf Kırpma</DialogTitle>
          <Cropper
            src={src}
            guides={false}
            ref={cropperRef}
            ready={onImageLoaded}
            zoomable={false}
            autoCropArea={1}
            {...({ initialAspectRatio: NaN } as any)}
          />
          <Button
            variant="default"
            className="!h-fit px-4 py-2"
            onClick={() => handleUpload()}
          >
            Onayla ve Yükle
          </Button>
        </DialogContent>
      </Dialog>
    </PlateElement>
  );
}
