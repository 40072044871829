import React from "react";
import { PlateElement, PlateElementProps } from "@udecode/plate-common";
import { cva, VariantProps } from "class-variance-authority";
import { slugifyPlateHeading } from "@/lib/slugify";

const headingVariants = cva("", {
  variants: {
    variant: {
      h1: "font-heading mb-[0.8em] mt-0 text-3xl font-bold",
      h2: "font-heading mb-[0.8em] mt-[1.6em] text-xl font-semibold tracking-tight",
      h3: "font-heading mb-[0.5em] mt-[1em] text-lg font-semibold tracking-tight",
      h4: "font-heading mb-[0.5em] mt-[1em] text-base font-semibold tracking-tight",
      h5: "mb-[0.5em] mt-[1.25em] text-base font-semibold tracking-tight",
      h6: "mb-[0.5em] mt-[1.25em] text-base font-semibold tracking-tight",
    },
    isFirstBlock: {
      true: "mt-0",
      false: "",
    },
  },
});

export function HeadingElement({
  className,
  variant = "h1",
  children,
  ...props
}: PlateElementProps & VariantProps<typeof headingVariants>) {
  const { element, editor } = props;

  const Element = variant!;

  return (
    <PlateElement
      asChild
      className={headingVariants({
        variant,
        className,
        isFirstBlock: element === editor.children[0],
      })}
      id={slugifyPlateHeading(element).slug}
      {...props}
    >
      <Element>{children}</Element>
    </PlateElement>
  );
}
