import { useEditorRef } from "@udecode/plate-common";

import { PlateCloudEditor } from "../cloud/types";
import { Upload } from "./types";

/**
 * Takes an `element` (which it only needs for its `id`) and returns the
 * Upload object from it.
 */
export const useUpload = (id: string | undefined, url: string): Upload => {
  const editor = useEditorRef() as PlateCloudEditor;

  /**
   * We call this even if it's not always required because it calls `useStore`
   * which is a React hook which means it needs to be called consistently.
   */
  // const upload: Upload = editor.cloud.useUploadStore(
  //   (state) => state.uploads[id] || { status: 'not-found' }
  // );

  const upload: Upload = id
    ? editor.cloud?.uploadStore.use.upload(id) || {
        status: "not-found",
      }
    : { status: "not-found" };

  if (url.includes("/") && !url.startsWith("blob:")) {
    return {
      status: "success",
      url: url,
    };
  }

  // (
  //   (state) => state.uploads[id] || { status: 'not-found' }
  // );
  return upload;
};
