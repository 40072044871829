import {
  ClientFile,
  createClientFile,
  uploadFile as uploadFileToPiAws,
} from "../pi-aws-client";
import { nanoid, Value } from "@udecode/plate-common";

import { FileEvent, PlateCloudEditor, ProgressEvent } from "./types";

const createFileEvent = (id: string, clientFile: ClientFile): FileEvent => {
  if (clientFile.type === "image") {
    return {
      id,
      type: "image",
      file: clientFile.file,
      url: clientFile.objectUrl,
      width: clientFile.width,
      height: clientFile.height,
    };
  }
  return {
    id,
    type: "generic",
    file: clientFile.file,
    url: clientFile.objectUrl,
  };
};

export const uploadFile = <V extends Value = Value>(
  editor: PlateCloudEditor<V>,
  file: File,
) => {
  const id = `#${nanoid()}`;
  const { client } = editor.cloud;

  createClientFile(file).then((clientFile) => {
    const fileEvent = createFileEvent(id, clientFile);
    if (fileEvent.type === "image") {
      // we will first crop the image, then upload it
      editor.cloud.imageFileHandlers?.onStart?.(fileEvent);
    } else {
      uploadFileToPiAws({
        client,
        file,
        onBeforeFetch(e) {
          const fileEvent = createFileEvent(id, e.clientFile);
          editor.cloud.genericFileHandlers?.onStart?.(fileEvent);
        },
        onProgress(e) {
          const fileEvent = createFileEvent(id, e.clientFile);
          const progressEvent: ProgressEvent = {
            sentBytes: e.sentBytes,
            totalBytes: e.totalBytes,
          };
          editor.cloud.genericFileHandlers?.onProgress?.({
            ...fileEvent,
            ...progressEvent,
          });
        },
        onError(e) {
          const fileEvent = createFileEvent(id, e.clientFile);
          editor.cloud.genericFileHandlers?.onError?.({
            ...fileEvent,
            message: e.message,
          });
        },
        onSuccess(e) {
          const fileEvent = createFileEvent(id, e.clientFile);
          const { url } = e.hostedFile;
          editor.cloud.genericFileHandlers?.onSuccess?.({ ...fileEvent, url });
        },
      });
    }
  });
};

export const uploadFiles = <V extends Value = Value>(
  editor: PlateCloudEditor<V>,
  files: Iterable<File>,
) => {
  Array.from(files).forEach((file) => uploadFile(editor, file));
};
